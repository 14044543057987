import { useState } from 'react'
import { jsPDF } from 'jspdf'
import autoTable from 'jspdf-autotable'
import './fonts/Montserrat-normal'

type id = number
type invoiceNumbers = string
type senderAddress = string
type recipientAddress = string

interface shipment {
	id: id
	invoiceNumbers: invoiceNumbers
	senderAddress: senderAddress
	recipientAddress: recipientAddress
}

export const App = () => {
	const [shipments, setShipments] = useState<shipment[]>([])
	const [invoiceNumbers, setInvoiceNumbers] = useState<invoiceNumbers>('')
	const [senderAddress, setSenderAddress] = useState<senderAddress>(localStorage.getItem('senderAddress') || '')
	const [recipientAddress, setRecipientAddress] = useState<recipientAddress>('')
	const [isRememberSenderAddress, setIsRememberSenderAddress] = useState<boolean>(localStorage.getItem('senderAddress') ? true : false)

	const addShipment = (e: { preventDefault: () => void }) => {
		e.preventDefault()
		const newShipment: shipment = {
			id: new Date().getTime(),
			invoiceNumbers,
			senderAddress,
			recipientAddress
		}
		setShipments([...shipments, newShipment])
		setInvoiceNumbers('')
		setRecipientAddress('')
		if (!isRememberSenderAddress) setSenderAddress('')
	}

	const deleteShipment = (id: id) => {
		const newShipments = shipments.filter(shipment => shipment.id !== id)
		setShipments(newShipments)
	}

	const editShipment = (id: id) => {
		const shipment = shipments.find(shipment => shipment.id === id)
		if (!shipment) return alert('Nie znaleziono wysyłki')
		setInvoiceNumbers(shipment.invoiceNumbers)
		setRecipientAddress(shipment.recipientAddress)
		deleteShipment(id)
	}

	const generateShipments = () => {
		const doc = new jsPDF({
			unit: 'mm',
			format: 'a4',
			compress: true
		})
		doc.setFont('Montserrat', 'normal')
		shipments.forEach((shipment, index) => {
			if (index > 0) doc.addPage()
			doc.setFontSize(10)
			doc.text(shipment.senderAddress, 145, 10, { angle: 270, maxWidth: 80 })
			doc.setFontSize(12)
			doc.text(shipment.recipientAddress, 60, 100, { angle: 270, maxWidth: 100 })
		})
		doc.save('listy.pdf')
	}

	const generateConfirmationShipments = () => {
		const doc = new jsPDF({
			unit: 'mm',
			format: 'a4',
			compress: true
		})
		doc.setFont('Montserrat', 'normal')
		shipments.forEach((shipment, index) => {
			if (index > 0) doc.addPage()
			doc.setFontSize(6)
			doc.text(shipment.invoiceNumbers, 92, 15, { angle: 270, maxWidth: 45 })
			doc.setFontSize(8)
			doc.text(shipment.recipientAddress, 75, 15, { angle: 270, maxWidth: 65 })
			doc.text('x', 40, 17, { angle: 270 })
			doc.setFontSize(10)
			doc.text(shipment.senderAddress, 44, 68, { angle: 270, maxWidth: 80 })
		})
		doc.save('zwrotki.pdf')
	}

	const generateSendConfirmation = () => {
		const doc = new jsPDF()
		doc.setFont('Montserrat', 'normal')
		doc.setFontSize(8)
		doc.text(senderAddress, 10, 10)
		const today = new Date()
		const { year, month, day } = {
			year: today.getFullYear(),
			month: String(today.getMonth() + 1).padStart(2, '0'),
			day: String(today.getDate()).padStart(2, '0')
		}
		doc.text(`Kaziny, ${year}-${month}-${day}`, 200, 10, { align: 'right' })
		autoTable(doc, {
			head: [['Lp.', 'Adresat', 'Numery faktur', 'Numer nadawczy', 'Uwagi', 'Opłata', 'Kwota pobrania']],
			body: shipments.map((shipment, index) => [index + 1, shipment.recipientAddress, shipment.invoiceNumbers]),
			margin: { top: 30, left: 10, right: 10 },
			styles: {
				font: 'Montserrat',
				fontStyle: 'normal',
				fontSize: 8
			},
			headStyles: {
				fillColor: [200, 200, 200],
				textColor: [0, 0, 0],
				lineColor: [150, 150, 150],
				valign: "middle",
				halign: "center"
			},
			columnStyles: {
				0: { cellWidth: 8 },
				1: { cellWidth: 70 },
				2: { cellWidth: 15 },
				3: { cellWidth: 55 },
				4: { cellWidth: 13 },
				5: { cellWidth: 13 },
				6: { cellWidth: 16 }
			},
			didDrawCell: function (data) {
				doc.rect(data.cell.x, data.cell.y, data.cell.width, data.cell.height, "S")
			},
			didParseCell: function (data) {
				if (data.column.index === 0 && data.row.section === "body") {
					data.cell.styles.halign = "center"
				}
			}
		})
		doc.save('potwierdzenie_nadania.pdf')
	}

	const setLocalStorage = (isRememberSenderAddress?: boolean, senderAddress?: string) => {
		if (senderAddress && isRememberSenderAddress && localStorage.getItem('senderAddress') !== senderAddress) {
			localStorage.setItem('senderAddress', senderAddress)
		} else {
			localStorage.clear()
		}
	}

	return (
		<div className="grid grid-cols-1 gap-4 lg:grid-cols-2 h-full p-4">
			<div className="rounded-lg bg-gray-200 p-4">
				<form onSubmit={addShipment} className="flex flex-col">
					<div className="my-2">
						<label
							htmlFor="senderAddress"
							className="block text-xs font-medium text-gray-700"
						>
							Adres nadawcy
						</label>
						<textarea
							id="senderAddress"
							placeholder="Wpisz adres nadawcy"
							rows={5}
							className="mt-1 w-full rounded-md shadow-sm sm:text-sm p-3"
							onChange={(e) => {
								setSenderAddress(e.target.value)
								setLocalStorage(isRememberSenderAddress, e.target.value)
							}}
							value={senderAddress}
							required
						/>

					</div>

					<div className='m-2'>
						<input
							id='rememberSenderAddress'
							name='rememberSenderAddress'
							onChange={() => {
								setIsRememberSenderAddress(!isRememberSenderAddress)
								setLocalStorage(!isRememberSenderAddress, senderAddress)
							}}
							checked={isRememberSenderAddress}
							type='checkbox' />
						<label htmlFor='rememberSenderAddress' className='p-2 text-sm text-gray-700'>Zapamiętaj adres nadawcy</label>
					</div>

					<div className="my-2">
						<label
							htmlFor="invoiceNumbers"
							className="block text-xs font-medium text-gray-700"
						>
							Numery faktur
						</label>
						<input
							type="text"
							id="invoiceNumbers"
							placeholder="Wpisz numery faktur"
							className="mt-1 w-full rounded-md shadow-sm sm:text-sm p-3"
							onChange={(e) => setInvoiceNumbers(e.target.value)}
							value={invoiceNumbers}
							required
						/>
					</div>
					<div className="my-2">
						<label
							htmlFor="recipientAddress"
							className="block text-xs font-medium text-gray-700"
						>
							Adres odbiorcy
						</label>
						<textarea
							id="recipientAddress"
							placeholder="Wpisz adres odbiorcy"
							rows={5}
							className="mt-1 w-full rounded-md shadow-sm sm:text-sm p-3"
							onChange={(e) => setRecipientAddress(e.target.value)}
							value={recipientAddress}
							required
						/>
					</div>
					<button
						type='submit'
						className="inline-block rounded bg-indigo-600 px-8 py-3 text-sm font-medium text-white transition hover:scale-110 hover:shadow-xl focus:outline-none focus:ring active:bg-indigo-500 self-end"
					>
						Dodaj
					</button>
				</form>
			</div>
			<div className="rounded-lg bg-gray-200 flex flex-col h-full overflow-hidden">
				{shipments.length ? (
					<>
						<ul className='flex-1 px-4 pt-2 overflow-auto'>
							{shipments.map(shipment => (
								<li key={shipment.id} className='bg-white rounded-md my-2 p-3 flex'>
									<div className='flex-1'>
										<header className='text-xs'>{shipment.invoiceNumbers}</header>
										<p>{shipment.recipientAddress}</p>
									</div>
									<span className="inline-flex overflow-hidden rounded-md border bg-white shadow-sm ">
										<button
											className="inline-block border-e p-3 text-gray-700 hover:bg-gray-50 focus:relative"
											title="Edit Product"
											onClick={() => editShipment(shipment.id)}
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												fill="none"
												viewBox="0 0 24 24"
												strokeWidth="1.5"
												stroke="currentColor"
												className="h-4 w-4"
											>
												<path
													strokeLinecap="round"
													strokeLinejoin="round"
													d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
												/>
											</svg>
										</button>

										<button
											className="inline-block p-3 text-gray-700 hover:bg-gray-50 focus:relative"
											title="Delete Product"
											onClick={() => deleteShipment(shipment.id)}
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												fill="none"
												viewBox="0 0 24 24"
												strokeWidth="1.5"
												stroke="currentColor"
												className="h-4 w-4"
											>
												<path
													strokeLinecap="round"
													strokeLinejoin="round"
													d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
												/>
											</svg>
										</button>
									</span>
								</li>
							))}
						</ul>
						<div className='p-4 flex justify-end w-full flex-wrap'>
							<button
								onClick={generateShipments}
								className="inline-block rounded bg-indigo-600 px-8 py-3 text-sm font-medium text-white transition hover:scale-110 hover:shadow-xl focus:outline-none focus:ring active:bg-indigo-500"
							>
								Listy
							</button>
							<button
								onClick={generateConfirmationShipments}
								className="inline-block rounded border border-current px-8 py-3 text-sm font-medium text-indigo-600 transition hover:scale-110 hover:shadow-xl focus:outline-none focus:ring active:text-indigo-500 mx-3"
							>
								Zwrotki
							</button>
							<button
								onClick={generateSendConfirmation}
								className="inline-block rounded bg-indigo-600 px-8 py-3 text-sm font-medium text-white transition hover:scale-110 hover:shadow-xl focus:outline-none focus:ring active:bg-indigo-500"
							>
								Potwierdzenie nadania
							</button>
						</div>

					</>
				) : (
					<p className='text-gray-500 text-xl h-full flex justify-center items-center select-none'>
						Brak wysyłek
					</p>
				)}
			</div>
		</div >
	)
}
